import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", null, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Home")
        ])),
        _: 1
      }),
      _cache[3] || (_cache[3] = _createTextVNode(" | ")),
      _createVNode(_component_router_link, { to: "/about" }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("About")
        ])),
        _: 1
      })
    ]),
    _createVNode(_component_MainButton, {
      text: "Open alert",
      onClick: _cache[0] || (_cache[0] = () => _ctx.showAlert('Hello!'))
    }),
    _createVNode(_component_router_view)
  ], 64))
}