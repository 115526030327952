import axios from 'axios'

export const axiosInstance = axios.create({
  baseURL: 'https://api.pshk.club'
})

export const setInterceptors = () => {
  axiosInstance.interceptors.request.use(function (config) {
    console.log('axios.request', config.method, config.url, config.data || null)

    return config
  }, function (error) {
    return Promise.reject(error)
  })

  axiosInstance.interceptors.response.use((response) => {
    if (response.config.method !== 'get') {
      // ctx.$notify({
      //   title: 'Success',
      //   message: 'Request completed successfully',
      //   type: 'success',
      //   duration: 1000
      // })
    }

    return response
  }, (error) => {
    return new Promise(function () {
      // const { config, response } = error
      //
      // if (config && !config.__isRetryRequest && response) {
      //   const { data } = response
      //   const h = ctx.$createElement
      //
      //   ctx
      //     .$msgbox({
      //       title: data.code || data.error || 'Error',
      //       message: h(
      //         'div', {
      //           staticStyle: {
      //             display: 'flex',
      //             'flex-direction': 'row',
      //             'align-items': 'center'
      //           }
      //         }, [
      //           h('IconPoo', {
      //             staticClass: 'message-box__icon',
      //             staticStyle: {
      //               'margin-right': '10px'
      //             },
      //             props: {
      //               size: 32,
      //               color: '#F56C6C'
      //             }
      //           }),
      //           h('span', {}, [
      //             data.message
      //           ])
      //         ]),
      //       type: 'error',
      //       showCancelButton: false,
      //       iconClass: 'el-icon-poo'
      //     })
      //     .then(() => null)
      //     .catch(() => null)
      // }
      throw error
    })
  })
}
