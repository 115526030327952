<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>

  <MainButton text="Open alert" @click="() => showAlert('Hello!')"></MainButton>

  <router-view/>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { setInterceptors } from '@/core/api'
import { MainButton, useWebAppPopup } from 'vue-tg'

export default defineComponent({
  components: {
    MainButton
  },

  setup () {
    const { showAlert } = useWebAppPopup()

    return {
      showAlert
    }
  },

  created () {
    setInterceptors()
  }
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
